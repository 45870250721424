<template>
  <div class="wwa_box main">
    <div class="container content">
      <video
        poster="@/assets/images/video1_poster.jpg"
        :src="$OSSURL + 'video1.mp4'"
        preload="true"
        class="wwa_video"
        controls
      ></video>
      <ul class="list_">
        <li>
          <div class="logo"><img src="@/assets/images/wwa_logo1.jpg" /></div>

          <div class="con">
            <div class="title_">IECIA</div>
            <div class="text_">
              Founded in 2014, IECIA – International Entrepreneurs, Creatives
              and Innovators Association, is the first global non-profit
              organization committed to promotion, recognition, research,
              training and consulting of innovation in digital economy. IECI
              achieves innovation through the recognition of ECI awards and the
              education of ECI Academy while providing resources and services
              through ECI Festivals to promote the collaboration and success
              among the Entrepreneurs, Creatives, and Innovators in today’s
              digital economy.
            </div>
          </div>
        </li>
        <li>
          <div class="logo"><img src="@/assets/images/wwa_logo2.jpg" /></div>

          <div class="con">
            <div class="title_">ECI Awards</div>
            <div class="text_">
              The ECI Awards founded in 2014 was established jointly by Chinese
              scholars and global digital innovators from 27 countries and
              regions activated by an educational institute of philanthropy,
              academic and independent study IECIA. The ECI awards is the first
              universal honor to utilize an evaluation standard to measure “The
              effectiveness of Innovation”. It recognizes those people and
              organizations with excellent achievements in business models,
              products, services, technology, marketing and management, which
              inspired the implementation of commercial and creative industries
              around the world. The ECI Awards has been renowned as the “Nobel
              Prize” for defining the future of the digital economy.
            </div>
          </div>
        </li>
        <li>
          <div class="logo"><img src="@/assets/images/wwa_logo3.jpg" /></div>

          <div class="con">
            <div class="title_">ECI Festival</div>
            <div class="text_">
              ECI Festival is the celebration of digital innovation from around
              the world gathered in China. It is a joint effort by ECI awards
              from the 27 countries and regions. This event has been known as
              one of the top four global festivals together with CES, MWC and
              IFA. ECI Festival is held in the fourth quarter of each year. With
              “Bring Innovation to Life” as its core value, it integrates
              creativity, entrepreneurship, venture capital and innovation into
              a single event to be celebrated with the audience from the world’s
              most valuable brands and agencies, prominent technology
              representatives, marketing gurus, and investors. Each year it
              attracts over 1,000 industry experts and close to 2,000 companies
              from all the regions of the world. Event includes our influential
              discussion forums, annual award ceremony, innovation launch
              parties, networking, themed expos, innovation camps, and more.
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WhoWeAre',
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/whoweare.scss';
</style>
